<template>
    <div class="fee-structures">
        <v-row justify="center">
            <v-col md="6">
                <intract-smart-list
                 class="mt-4"
                 v-if="feeStructures"
                 ref="fee-structures-smart-list"
                 :items="allRooms"
                >
                    <template v-slot:list-item="{ item: classroom }">
                        <v-list-item
                        @click="() => openFeeStructureInstalment(classroom.id)"
                        class="non-focused"
                        >
                        <v-list-item-content>
                            <v-list-item-title>{{ classroom.sections }}</v-list-item-title>
                            <v-list-item-subtitle>{{ feeStructures.find(fs => fs.room == classroom.id) ? feeStructures.find(fs => fs.room == classroom.id).structure_instalments_count: 0 }} Instalments</v-list-item-subtitle>   
                        </v-list-item-content>
                        <v-list-item-icon
                            ><v-icon>mdi-arrow-right</v-icon></v-list-item-icon
                        >
                        </v-list-item>
                        <!-- <v-divider class="mx-4"></v-divider> -->
                    </template>
                </intract-smart-list>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import Mixins from "@utils/mixins";
// import IntractCreateEditSheet from "@components/generics/IntractCreateEditSheet";
import IntractSmartList from "@components/generics/IntractSmartList";
// import IntractListSheet from "@components/generics/IntractListSheet";
import moment from "moment";
import { mapGetters } from "vuex";
export default{
    name: "FeeStructures",
    mixins: [Mixins.essentials, Mixins.pullToRefresh],
    components:{
        // IntractCreateEditSheet,
        IntractSmartList,
        // IntractListSheet
    },
    data(){
        return {
            moment: moment,
            feeStructures: []
        }
    },
    computed:{
        ...mapGetters(["allRooms"]),
    },
    methods:{
        async getFeeStructures(){
            const url = this.endpoints.feeStructuresViewSet
            this.feeStructures = await this.api.call(this.essentials, url)
            // feeStructures.forEach(fs => this.feeStructures[fs.room] = fs)
        },
        async getOrCreateFeeStructure(classroomId){
            let feeStructure = this.feeStructures.find(fs => fs.room == classroomId)
            if(!feeStructure){
                const url = this.endpoints.feeStructuresViewSet       
                const data = {
                    institution: this.currentInstitution.id,
                    room: classroomId
                }
                feeStructure = await this.api.call(this.essentials, url, this.api.Methods.POST, data)
            }
            return feeStructure
        },
        async openFeeStructureInstalment(classroomId){
            const feeStructure = await this.getOrCreateFeeStructure(classroomId)
            this.$router.push({
                name: 'FeeStructureInstalments',
                params: {
                    structure_id: feeStructure.id
                } 
            })
        }
        
    },
    created(){
        this.getFeeStructures();
    }
}
</script>